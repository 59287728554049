<template>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    elevation="24"
    location="top"
  >
    {{ snackbar.text }}
    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
        icon="mdi-close"
        @click="snackbar = false"
      />
    </template>
  </v-snackbar>
  <div class="home">
    <img class="logo_ponce" :src="ponceLogoUrl" alt="PONCE">
    <div class="config_div">
      <img v-if="organizationLogoURL" class="logo_organization" :src="organizationLogoURL">
      <p class="text-white setting_name">{{settingName}}</p>
    </div>
    <DevicesMap
      v-if="devicesGroups.length && !reloadNeeded"
      ref="devicesMap"
      :configurationDevicesGroups="devicesGroups"
      :maxTimePerGroup="maxTimePerGroup"
      :timePerDevice="timePerDevice"
      :nameTimer="nameTimer"
    />
    <div v-else-if="!reloadNeeded" class="justify-center blue_background">
      <v-progress-circular
        indeterminate
        color="#FFFFFF"
        style="align-self: center"
      />
      <p class="my-5" style="color: #FFFFFF;">{{$t('Home.gettingDevices')}}...</p>
    </div>
    <div v-else class="justify-center blue_background">
      <ReloadHelper :text="$t('Home.configChanged')"/>
    </div>
  </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import DevicesMap from '@/components/DevicesMap.vue'
import ReloadHelper from '@/components/ReloadHelper.vue'
import { storage, auth } from '@/plugins/firebase.js'
import constants from '../constants'
import { hasChangesInDeviceGroups } from '@/libs/helpers'
const { EVENTS, EXTERNAL_ROUTES, HTTP_STATUS_CODES, NAME_TIMERS } = constants

export default {
  name: 'home-view',
  components: {
    DevicesMap,
    ReloadHelper
  },
  data () {
    return {
      devicesGroups: [],
      maxTimePerGroup: 60000,
      timePerDevice: 5000,
      settingName: '',
      organizationLogoURL: '',
      nameTimer: NAME_TIMERS.NEVER.VALUE,
      refreshConfigurationTime: 60,
      refreshConfigInterval: null,
      reloadNeeded: false,
      snackbar: {
        show: false,
        timeout: 0,
        text: '',
        color: ''
      },
      ponceLogoUrl: process.env.VUE_APP_LOGO_URL
    }
  },
  async created () {
    this.mixpanel.track_pageview({"page": "Home"})
    if (!this.$route.params.configurationId) {
      this.mixpanel.track(EVENTS.TO_NOT_FOUND, {
        userAgent: navigator.userAgent || '',
        config: this.$route.params.configurationId,
        cause: 'Config ID null'
      })
      this.$router.push('/not-found')
    } else {
      this.mixpanel.track(EVENTS.GET_CONFIG, {
        userAgent: navigator.userAgent || '',
        sessionId: _get(this, '$store.getters.user.uid', null),
        orgId: this.$store.getters.orgId,
        config: this.$route.params.configurationId
      })
      await this.getConfig()
      this.refreshConfigInterval = setInterval(async () => {
        await this.getConfig()
        if (this.$refs.devicesMap) {
          this.$refs.devicesMap.refreshDevicesGroups()
        }
      }, this.refreshConfigurationTime * 1000)
    }
  },
  methods: {
    async getConfig () {
      try {
        const headers = {
          'authorization': process.env.VUE_APP_WEBHOOK_AUTH
        }

        this.$store.getters.widget
          ? headers['jwt-token'] = this.$store.getters.token
          : headers['auth-token'] = await auth.client.currentUser.getIdToken(true)

        const request = await fetch(`${EXTERNAL_ROUTES.WEBHOOK.BASE}${EXTERNAL_ROUTES.WEBHOOK.GET_CONFIGURATION}?id=${this.$route.params.configurationId}`, {
          headers
        })
        if (request.ok) {
          const response = await request.json()
          const {name, devicesGroups, maxTimePerGroup, timePerDevice, logo, nameTimer} = response
          if (logo) {
            const logoRef = storage.ref(storage.client, logo)
            this.organizationLogoURL = await storage.getDownloadURL(logoRef)
          }
          this.settingName = name
          this.devicesGroups = devicesGroups,
          this.maxTimePerGroup = maxTimePerGroup,
          this.timePerDevice = timePerDevice,
          this.nameTimer = nameTimer || NAME_TIMERS.NEVER.VALUE
        } else {
          // Token revoked
          if (request.status === HTTP_STATUS_CODES.FORBIDDEN) {
            this.mixpanel.track(EVENTS.LOGOUT, {
              userAgent: navigator.userAgent || '',
              sessionId: _get(this, '$store.getters.user.uid', null),
              orgId: this.$store.getters.orgId
            })
            this.clearTimeouts()
            return this.$router.replace(`/setup/${this.$route.params.configurationId}`)
          }
          // Config not found
          if (request.status === HTTP_STATUS_CODES.NOT_FOUND) {
            this.mixpanel.track(EVENTS.TO_NOT_FOUND, {
              userAgent: navigator.userAgent || '',
              config: this.$route.params.configurationId,
              cause: 'Config not found'
            })
            this.clearTimeouts()
            return this.$router.replace(`/not-found`)
          }
          this.showError(this.$t('Home.getConfigError'))
        }
      } catch (error) {
        // Token revoked
        if (error.code === 'auth/user-token-expired') {
          this.mixpanel.track(EVENTS.LOGOUT, {
            userAgent: navigator.userAgent || '',
            sessionId: _get(this, '$store.getters.user.uid', null),
            orgId: this.$store.getters.orgId
          })
          this.clearTimeouts()
          return this.$router.replace(`/setup/${this.$route.params.configurationId}`)
        }
        this.showError(this.$t('Home.getConfigError'))
      }
    },
    clearTimeouts () {
      // Clear Refresh Config interval
      clearInterval(this.refreshConfigInterval)
      // Clear changeToNextDevicesGroup timeout
      if (this.$refs.devicesMap) this.$refs.devicesMap.clearDevicesTimeout()
    },
    showError (text) {
      this.snackbar = {
        show: true,
        timeout: -1,
        text,
        color: 'red'
      }
    }
  },
  watch: {
    devicesGroups (newValue, oldValue) {
      if (!_isEmpty(oldValue) && hasChangesInDeviceGroups(oldValue, newValue)) {
        this.reloadNeeded = true
        this.clearTimeouts()
      }
    }
  }
}
</script>

<style scoped>
  .home {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .logo_ponce {
    position: absolute;
    top: 3%;
    left: 3%;
    max-width: 200px;
    width: 40%;
    height: auto;
    z-index: 1;
  }

  .logo_organization {
    max-width: 100%;
    height: auto;
  }

  .blue_background {
    display: flex;
    flex-flow: column;
    height: 100%;
    background: #0098E1;
  }

  .config_div {
    position: absolute;
    top: 3%;
    right: 3%;
    max-width: 200px;
    width: 40%;
    height: auto;
    z-index: 1;
  }

  .setting_name {
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: 1px 0 10px black;
  }
</style>
