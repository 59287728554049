<template>
  <router-view/>
</template>

<script>
import constants from './constants'

const { EVENTS } = constants

export default {
  name: 'ponce-tv',
  async created () {
    this.mixpanel.track(EVENTS.OPEN_APP, {
      userAgent: navigator.userAgent || '',
      sessionId: this.$store.getters.user.uid,
      orgId: this.$store.getters.orgId
    })
    this.mixpanel.time_event(EVENTS.LOGIN)
    this.mixpanel.time_event(EVENTS.CLOSE_APP)
    // Check if app is running from widget
    if (document.currentScript.getAttribute('widget')) {
      const token = document.currentScript.getAttribute('token')
      const config = document.currentScript.getAttribute('config')
      if (token && config) {
        this.$store.commit('setWidget', true)
        this.$store.commit('setToken', token)
        this.$router.push(`/${config}`)
        this.mixpanel.track(EVENTS.LOGIN, {
          userAgent: navigator.userAgent || '',
          type: 'widget'
        })
        this.mixpanel.time_event(EVENTS.CLOSE_APP)
      } else {
        this.$router.replace(`/not-found`)
        this.mixpanel.track(EVENTS.TO_NOT_FOUND, {
          userAgent: navigator.userAgent || '',
          config,
          cause: 'Login failed'
        })
      }
    }
  },
  mounted () {
    const width = document.currentScript.getAttribute('width') || '100vw'
    const height = document.currentScript.getAttribute('height') || '100vh'
    const appDiv = document.getElementById('ponce-tv')
    appDiv.style.width = width
    appDiv.style.height = height
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  beforeUnmount () {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  methods: {
    handleBeforeUnload () {
      this.mixpanel.track(EVENTS.CLOSE_APP, {
        userAgent: navigator.userAgent || '',
        sessionId: this.$store.getters.user.uid,
        orgId: this.$store.getters.orgId
      })
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

#ponce-tv {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
}
</style>
