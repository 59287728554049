import { store } from '../store'
import isEmpty from 'lodash/isEmpty'

export default (to, from, next) => {
  // If the Widget flag is true, dont validate user state
  if (store.getters.widget) return next()

  const user = store.getters.user

  // Route to loading view
  if (to.name === 'loading') return next()

  // The auth client is loading
  if (store.getters.loading) {
    // The configuration id exists
    if (to.name === 'home' && to.params.configurationId) return next({ path: '/loading', query: {...to.query, to: to.path} })

    // The configuration id is not defined
    return next('/not-found')
  }

  // The user session already exists
  if (!isEmpty(user)) return next()

  // Redirect to setup page if there is no user session
  return next(`/setup/${to.params.configurationId}`)
}