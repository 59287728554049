import { auth } from '../plugins/firebase.js'
import { store } from '../store'

auth.onAuthStateChanged(auth.client, (user) => {
  if (user) {
    store.commit('setUser', user)
  } else {
    store.commit('setUser', {})
  }
  store.commit('setLoading', false)
})