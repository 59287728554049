import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({user: state.user, orgId: state.orgId})
})

// Create a new store instance.
export const store = createStore({
  state () {
    return {
      user: {},
      loading: true,
      orgId: null,
      widget: false,
      token: null
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    loading (state) {
      return state.loading
    },
    orgId (state) {
      return state.orgId
    },
    widget (state) {
      return state.widget
    },
    token (state) {
      return state.token
    }
  },
  mutations: {
    setUser (state, value) {
      state.user = value
    },
    setLoading (state, value) {
      state.loading = value
    },
    setOrgId (state, value) {
      state.orgId = value
    },
    setWidget (state, value) {
      state.widget = value
    },
    setToken (state, value) {
      state.token = value
    }
  },
  plugins: [vuexLocal.plugin]
})