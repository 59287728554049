<template>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    elevation="24"
    location="top"
  >
    {{ snackbar.text }}
    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
        icon="mdi-close"
        @click="snackbar = false"
      />
    </template>
  </v-snackbar>
  <div style="display: flex; flex-flow: column; height: 100%; background: #0098E1;">
    <v-row class="my-0 mx-0" style="flex: 0 1 auto;">
      <div class="py-3" style="max-height: 15vh; width:100%">
        <v-img :src="ponceLogoUrl"></v-img>
      </div>
    </v-row>
    <v-row class="my-0 mx-5 h-20">
      <v-col cols="12" sm="6" class="align-self-center">
        <transition name="switch" mode="out-in">
          <div v-if="code">
            <p v-if="!expired" style="color: #FFFFFF; font-size: 4rem; font-weight: bold;">{{ code }}</p>
            <ReloadHelper v-else :text="$t('SetupScreen.expiredCode')" />
          </div>
          <div v-else class="text-center">
            <v-progress-circular
            indeterminate
            color="#FFFFFF"
            />
            <p class="my-5" style="color: #FFFFFF;">{{$t('SetupScreen.generatingCode')}}...</p>
          </div>
        </transition>
      </v-col>
      <v-col cols="12" sm="6" class="align-self-center">
        <h2 class="mb-2" style="color: #FFFFFF;" v-text="$t('SetupScreen.configuration')"></h2>
          <p style="color: #FFFFFF;">{{$t('SetupScreen.followInstructions')}}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { firestore, auth } from '@/plugins/firebase.js'
import ReloadHelper from '@/components/ReloadHelper.vue'
import randomstring from 'randomstring'
import get from 'lodash/get'
import moment from 'moment'
import constants from '../constants'
const { EVENTS, EXTERNAL_ROUTES, CODE_EXPIRATION_MINUTES } = constants

export default {
  name: 'setup-screen',
  components: {
    ReloadHelper
  },
  data () {
    return {
      code: '',
      expired: false,
      snackbar: {
        show: false,
        timeout: 0,
        text: '',
        color: ''
      },
      ponceLogoUrl: process.env.VUE_APP_LOGO_URL
    }
  },
  async created () {
    await this.generateCode()
    await this.storeToken()
    this.mixpanel.track_pageview({"page": "SetupScreen"})
  },
  methods: {
    async validateCode (code) {
      try {
        const docRef = firestore.doc(firestore.db, 'appTv/v1/authenticationCodes', code)
        const docSnap = await firestore.getDoc(docRef)
        return !docSnap.exists()
      } catch (error) {
        // Code already exists but its expired
        if (error.code === 'permission-denied') return false

        // Other errors
        this.showError(this.$t('SetupScreen.error'))
        console.log(error.message)
        return false
      }
    },
    async generateCode () {
      const CODE_LENGTH = 5
      let code
      let isUniqueId = false
      while (!isUniqueId) {
        // Create random code
        code = randomstring.generate({
          length: CODE_LENGTH,
          charset: 'alphanumeric',
          capitalization: 'uppercase'
        })

        if (await this.validateCode(code)) {
          isUniqueId = true
          this.code = code
        }
      }
    },
    async storeToken () {
      try {
        // Create auth code document
        const expirationDate = moment().add(CODE_EXPIRATION_MINUTES, 'minutes').toDate()
        await firestore.setDoc(firestore.doc(firestore.db, 'appTv/v1/authenticationCodes', this.code), {
          expirationDate
        })
        // Trigger function on code document update
        const codeDocRef = firestore.doc(firestore.db, 'appTv/v1/authenticationCodes', this.code)
        const unsuscribe = firestore.onSnapshot(codeDocRef, async (doc) => {
          try {
            if (doc.exists() && doc.data()) {
              // If token exists, store it in vuex store
              const token = get(doc.data(), 'token', '')
              if (token) {
                // Login and store user in local storage
                const userCredential = await auth.signInWithCustomToken(auth.client, token)
                this.$store.commit('setUser', userCredential.user)

                // Confirm session login to API
                const request = await fetch(`${EXTERNAL_ROUTES.WEBHOOK.BASE}${EXTERNAL_ROUTES.WEBHOOK.CONFIRM_SESSION}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.VUE_APP_WEBHOOK_AUTH,
                    'auth-token': await auth.client.currentUser.getIdToken(true)
                  },
                  body: JSON.stringify({
                    code: this.code,
                    deviceInfo: {
                      userAgent: navigator.userAgent || '',
                    }
                  })
                })
                if (request.ok) {
                  const response = await request.json()
                  this.$store.commit('setOrgId', response.orgId)
                  this.mixpanel.track(EVENTS.LOGIN, {
                    userAgent: navigator.userAgent || '',
                    type: 'web',
                    sessionId: this.$store.getters.user.uid,
                    orgId: this.$store.getters.orgId
                  })
                  // Track time until App Close
                  this.mixpanel.time_event(EVENTS.CLOSE_APP)
                  // Route user to maps view and stop listening to the code document
                  this.$router.push(`/${this.$route.params.configurationId}`)
                  unsuscribe()
                } else {
                  this.showError(this.$t('SetupScreen.error'))
                }
              }
            }
          } catch (error) {
            this.showError(this.$t('SetupScreen.error'))
            console.log(error.message)
          }
        })

        // Alert user that code has expired
        setTimeout(async () => {
          this.expired = true
        }, expirationDate - new Date())

      } catch (error) {
        this.showError(this.$t('SetupScreen.error'))
        console.log(error.message)
      }
    },
    showError (text) {
      this.snackbar = {
        show: true,
        timeout: -1,
        text,
        color: 'red'
      }
    }
  }
}
</script>

<style>
  .switch-enter-from, .switch-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }
  .switch-enter-to, .switch-leave-from {
    opacity: 1;
    transform: translateY(0);
  }
  .switch-enter-active {
    transition: all 0.5s ease;
  }
  .switch-leave-active {
    transition: all 0.5s ease;
  }
</style>