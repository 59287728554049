import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router'
import HomeView from '../views/Home'
import SetupView from '../views/SetupScreen'
import EmptyView from '../views/Empty'
import AuthGuard from './auth-guard'
import LoadingView from '../views/LoadingScreen'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: '/not-found'
  },
  {
    path: '/:configurationId',
    name: 'home',
    component: HomeView,
    beforeEnter: AuthGuard
  },
  {
    path: '/setup/:configurationId',
    name: 'setup',
    component: SetupView
  },
  {
    path: '/loading',
    name: 'loading',
    component: LoadingView
  },
  {
    path: '/not-found',
    name: 'empty',
    component: EmptyView
  }
]

const router = createRouter({
  // Set the history mode depending on whether it is used as a widget or as a web page
  history: document.currentScript.getAttribute('widget') ? createMemoryHistory() : createWebHistory(),
  routes
})

export default router
