<template>
  <div class="justify-center" style="display: flex; flex-flow: column; height: 100%; background: #0098E1;">
    <v-progress-circular
      indeterminate
      color="#FFFFFF"
      style="align-self: center"
    />
    <p class="my-5" style="color: #FFFFFF;">{{$t('LoadingScreen.loading')}}...</p>
  </div>
</template>

<script>
export default {
  computed: {
    loading () {
      return this.$store.getters.loading
    }
  },
  watch: {
    loading (val) {
      if (!val) {
        const route = this.$route.query.to
        this.$router.replace(route)
      }
    }
  },
  created () {
    this.mixpanel.track_pageview({"page": "LoadingScreen"})
  }
}
</script>