<template>
  <div id="maps-container" style="height: 100%; width: 100%;" />
</template>

<script>
import { getLinearDeviceArmLinePath, getCropPoints } from '@/libs/helpers'
import { Loader } from '@googlemaps/js-api-loader'
import constants from '../constants'
const { EQUIPMENT_TYPE, MAPS_SETTINGS, NAME_TIMERS } = constants

export default {
  name: 'DevicesMap',
  props: {
    configurationDevicesGroups: Array,
    timePerDevice: Number,
    maxTimePerGroup: Number,
    nameTimer: Number
  },
  data () {
    return {
      maps: [],
      mapsElements: [],
      Map: {},
      currentMapIndex: 0,
      devicesGroups: [],
      devicesTimeout: null,
      infoWindows: []
    }
  },
  async created () {
    this.devicesGroups = this.configurationDevicesGroups
    await this.loadMapsLibrary()
    this.initGroupsMaps()
    if (this.devicesGroups.length > 1) {
      this.changeToNextDevicesGroup()
    }
  },
  methods: {
    async loadMapsLibrary () {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        version: 'weekly'
      })
      await loader.load()
      const { Map } = await google.maps.importLibrary('maps')
      this.Map = Map
      await google.maps.importLibrary('geometry')
    },
    initGroupsMaps () {
      this.devicesGroups.forEach(async (devices, index) => {
        this.infoWindows[index] = []
        await this.initDevicesGroupMap(index)
        this.drawDevices(devices, index)
        this.openInfoWindows()
      })
    },
    async initDevicesGroupMap (index) {
      // Create HTML element
      const mapsContainer = document.getElementById('maps-container')
      const mapElem = document.createElement('div')
      mapElem.id = `map-${index}`
      mapElem.style.display = index === 0 ? 'block' : 'none'
      mapElem.style.height = '100%'
      mapsContainer.appendChild(mapElem)

      // Create map
      this.maps[index] = new this.Map(document.getElementById(`map-${index}`), {
        maxZoom: 14,
        center: new google.maps.LatLng(51.501527, -0.1921837),
        mapTypeId: 'satellite',
        panControl: false ,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        rotateControl: false,
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        fullscreenControl: false,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM
        }
      })
    },
    async drawDevices (devices, groupIndex, isRefresh) {
      const currentMap = this.maps[groupIndex]
      const currentMapElements = []
      const currentMapBounds = new google.maps.LatLngBounds()
      devices.forEach((device, deviceIndex) => {
        if (device.type == EQUIPMENT_TYPE.PIVOT) {
          this.drawPivot(currentMap, currentMapElements, currentMapBounds, device, groupIndex, isRefresh, deviceIndex)
        } else if (device.type == EQUIPMENT_TYPE.LINEAR) {
          this.drawLinear(currentMap, currentMapElements, currentMapBounds, device, groupIndex, isRefresh, deviceIndex)
        }
      })

      // Put devices into map view
      if (!isRefresh)  {
        currentMap.panToBounds(currentMapBounds)
        currentMap.fitBounds(currentMapBounds)
      }
    },
    refreshDevicesGroups () {
      this.removeDevicesFromMap()
      this.devicesGroups.forEach((devices, index) => {
        this.drawDevices(devices, index, true)
      })
    },
    removeDevicesFromMap () {
      this.mapsElements.forEach(mapElements => {
        mapElements.forEach(element => {
          // Remove map shapes
          element.setMap(null)
        })
      })
      this.mapsElements = []
    },
    changeToNextDevicesGroup () {
      const currentDevicesGroup = this.devicesGroups[this.currentMapIndex]
      const timeout = currentDevicesGroup.length * this.timePerDevice > this.maxTimePerGroup ? this.maxTimePerGroup : currentDevicesGroup.length * this.timePerDevice
      this.devicesTimeout = setTimeout(() => {
        let oldMapIndex, newMapIndex
        oldMapIndex = this.currentMapIndex
        if (this.currentMapIndex < this.devicesGroups.length - 1) {
          newMapIndex = this.currentMapIndex + 1
        } else {
          newMapIndex = 0
        }
        this.currentMapIndex = newMapIndex
        document.getElementById(`map-${oldMapIndex}`).style.display = 'none'
        document.getElementById(`map-${newMapIndex}`).style.display = 'block'
        this.openInfoWindows()
        this.changeToNextDevicesGroup()
      }, timeout * 1000)
    },
    drawPivot (currentMap, currentMapElements, currentMapBounds, device, groupIndex, isRefresh, deviceIndex) {
      try {
        // Draw pivot crop circle
        const circle = new google.maps.Circle({
          strokeColor: device.color,
          strokeOpacity: 1,
          strokeWeight: 4,
          fillColor: device.color,
          fillOpacity: 0.3,
          center: device.center,
          radius: device.radius
        })
        circle.setMap(currentMap)
        circle.setOptions({zIndex: MAPS_SETTINGS.Z_INDEX.CROP})
        currentMapElements.push(circle)

        // Set info window default params
        const infoWindowParams = {
          content: device.name,
          position: device.center
        }

        if (device.angle) {
          // Draw pivot device position
          const positionCoordinates = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(device.center), device.radius, device.angle)
          const devicePositionPath = [
            device.center,
            positionCoordinates
          ]
          const devicePositionLine = new google.maps.Polyline({
            path: devicePositionPath,
            strokeColor: 'white',
            strokeOpacity: 1.0,
            strokeWeight: 2
          })
          devicePositionLine.setMap(currentMap)
          devicePositionLine.setOptions({zIndex: MAPS_SETTINGS.Z_INDEX.DEVICE})
          currentMapElements.push(devicePositionLine)

          infoWindowParams.position = positionCoordinates
        }

        if (!isRefresh) {
          // Add device to map bounds to display it on the map
          currentMapBounds.union(circle.getBounds())

          // Add infoWindow to show device name
          // If the "Hide name" setting is not "Always" create the Info Window
          if (this.nameTimer !== NAME_TIMERS.ALWAYS.VALUE) {
            this.infoWindows[groupIndex][deviceIndex] = new google.maps.InfoWindow(infoWindowParams)
          }

        } else {
          // If the "Hide name" setting is not "Always" update the Info Window position
          if (this.nameTimer !== NAME_TIMERS.ALWAYS.VALUE) {
            this.infoWindows[groupIndex][deviceIndex].setPosition(infoWindowParams.position)
          }
        }

        // Add shapes to current map elements
        this.mapsElements[groupIndex] = currentMapElements
      } catch (error) {
        console.log(`Error drawing device ${device.name}`, error.message)
      }
    },
    drawLinear (currentMap, currentMapElements, currentMapBounds, device, groupIndex, isRefresh, deviceIndex) {
      try {
        // Draw linear crop
        const cropLine = new google.maps.Polygon({
          paths: getCropPoints(device.subtype, device.currentLot, device.location, device.length),
          strokeColor: device.color,
          strokeOpacity: 1,
          strokeWeight: 4,
          fillColor: device.color,
          fillOpacity: 0.3,
        })
        cropLine.setMap(currentMap)
        currentMapElements.push(cropLine)

        // Set info window default params
        const infoWindowParams = {
          content: device.name,
          position: device.currentLot.startPoint
        }

        if (device.location.lat !== 0 && device.location.lng !== 0) {
          // Draw linear device position
          const devicePositionPath = getLinearDeviceArmLinePath(device.subtype, device.currentLot, device.location, device.length)
          const devicePositionLine = new google.maps.Polyline({
            path: devicePositionPath,
            strokeColor: 'white',
            strokeOpacity: 1.0,
            strokeWeight: 2
          })
          devicePositionLine.setMap(currentMap)
          currentMapElements.push(devicePositionLine)

          // Set info window position to device location
          infoWindowParams.position = device.location
        }

        if (!isRefresh) {
          // Add device to map bounds to display it on the map
          const bounds = new google.maps.LatLngBounds()
          cropLine.getPath().forEach(coordinate => {
            bounds.extend(coordinate)
          })
          currentMapBounds.union(bounds)

          // Add infoWindow to show device name
          const infoWindow = new google.maps.InfoWindow(infoWindowParams)
          infoWindow.open({
            map: currentMap
          })
          this.infoWindows[groupIndex][deviceIndex] = infoWindow
        } else {
          // If infoWindow already exist, update position
          this.infoWindows[groupIndex][deviceIndex].setPosition(infoWindowParams.position)
        }

        // Add shapes to current map elements
        this.mapsElements[groupIndex] = currentMapElements
      } catch (error) {
        console.log(`Error drawing device ${device.name}`, error.message)
      }
    },
    clearDevicesTimeout () {
      clearTimeout(this.devicesTimeout)
    },
    openInfoWindows () {
      switch (this.nameTimer) {
        case NAME_TIMERS.NEVER.VALUE:
          this.infoWindows[this.currentMapIndex].forEach(infoWindow => {
            infoWindow.open({map: this.maps[this.currentMapIndex]})
          })
          break
        case NAME_TIMERS.AFTER_10_SECONDS.VALUE:
          this.infoWindows[this.currentMapIndex].forEach(infoWindow => {
            infoWindow.open({map: this.maps[this.currentMapIndex]})

            // If the screen time of the group is greater than 10 seconds or it's only one group, then set the timeout
            if ((this.devicesGroups[this.currentMapIndex].length * this.timePerDevice > NAME_TIMERS.AFTER_10_SECONDS.TIME && this.maxTimePerGroup > NAME_TIMERS.AFTER_10_SECONDS.TIME) || this.devicesGroups.length === 1) {
              setTimeout(() => { infoWindow.close() }, NAME_TIMERS.AFTER_10_SECONDS.TIME * 1000) // Set timeout to 10 seconds
            }
          })
          break
        case NAME_TIMERS.AFTER_30_SECONDS.VALUE:
          this.infoWindows[this.currentMapIndex].forEach(infoWindow => {
            infoWindow.open({map: this.maps[this.currentMapIndex]})

            // If the screen time of the group is greater than 30 seconds or it's only one group, then set the timeout
            if ((this.devicesGroups[this.currentMapIndex].length * this.timePerDevice > NAME_TIMERS.AFTER_30_SECONDS.TIME && this.maxTimePerGroup > NAME_TIMERS.AFTER_30_SECONDS.TIME) || this.devicesGroups.length === 1) {
              setTimeout(() => { infoWindow.close() }, NAME_TIMERS.AFTER_30_SECONDS.TIME * 1000) // Set timeout to 30 seconds
            }
          })
          break
      }
    }
  },
  watch: {
    configurationDevicesGroups (value) {
      this.devicesGroups = value
    }
  }
}
</script>

<style>
.gm-style-iw button {display: none !important;}
</style>
