import { createApp } from 'vue'
import { store } from './store'
import { vuetify } from './plugins/vuetify'
import mixpanel from './plugins/mixpanel'
import App from './App.vue'
import router from './router'
import i18n from './libs/i18n'
import './libs/manage-user'

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .use(mixpanel)
  .mount('#ponce-tv')
