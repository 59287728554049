import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator, doc, onSnapshot, getDoc, query, where, collection, getDocs, documentId, setDoc, updateDoc, deleteField, deleteDoc } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL, connectStorageEmulator } from "firebase/storage"
import { connectAuthEmulator, getAuth, signInWithCustomToken, onAuthStateChanged } from "firebase/auth"

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)
const storageClient = getStorage()

// Initialize Auth and get a reference to the service
const authClient = getAuth()

// Connect to local emulator if the app is running locally
/*if (location.hostname === 'localhost') {
  connectAuthEmulator(authClient, 'http://localhost:9099')
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectStorageEmulator(storageClient, 'localhost', 9199)
}*/

const firestore = {
  db,
  doc,
  onSnapshot,
  getDoc,
  query,
  where,
  collection,
  getDocs,
  documentId,
  setDoc,
  updateDoc,
  deleteField,
  deleteDoc
}

const storage = {
  client: storageClient,
  ref,
  getDownloadURL
}

const auth = {
  client: authClient,
  signInWithCustomToken,
  onAuthStateChanged
}

export {
  firestore,
  storage,
  auth
}