<template>
  <div>
    <p style="color: #FFFFFF;">{{text}}</p>
    <p class="mb-5" style="color: #FFFFFF;">{{$t('ReloadHelper.reloadText')}}</p>
    <v-btn @click="reload" variant="tonal" style="color: #FFFFFF;" icon="mdi-reload">
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'ReloadHelper',
    props: {
      text: String,
    },
    methods: {
      reload () {
        location.reload()
      }
    }
  }
</script>