import { createI18n } from 'vue-i18n'
import { getBrowserLanguage } from './helpers'
import dictionaryES from '../lang/dictionaryES.json'
import dictionaryEN from '../lang/dictionaryEN.json'

const messages = {
  es: dictionaryES,
  en: dictionaryEN
}

const i18n = createI18n({
  locale: getBrowserLanguage(),
  messages
})

export default i18n
