<template>
  <div style="display: flex; flex-flow: column; height: 100%; background: #0098E1;">
    <v-row class="my-0 mx-0" style="flex: 0 1 auto;">
      <div class="py-3" style="max-height: 15vh; width:100%">
        <v-img :src="ponceLogoUrl"></v-img>
      </div>
    </v-row>
    <v-row class="my-0 mx-0 d-flex justify-center">
      <v-col class="px-10 pb-0 pt-16">
        <p class="text-h5 text-sm-h5 text-md-h3 font-weight-bold text-white" v-text="$t('EmptyView.configError')"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'empty-view',
  data () {
    return {
      ponceLogoUrl: process.env.VUE_APP_LOGO_URL
    }
  },
  created () {
    this.mixpanel.track_pageview({"page": "Empty"})
  }
}
</script>