export default {
  LANGUAGES: {
    DEFAULT_LANGUAGE: 'en',
    ACCEPTED_LANGUAGES: ['es', 'en']
  },
  EQUIPMENT_TYPE: {
    PIVOT: 1,
    LINEAR: 2,
    IR: 3
  },
  EQUIPMENT_SUBTYPE: {
    LINEAR: {
      TWO_WINGS: 1,
      ONE_WING: 2,
      PIVOTABLE: 3
    }
  },
  STATES: {
    DISCONNECTED: 0,
    OFF: 1,
    ALERT_PUMP: 2,
    ALARM_PUMP: 3,
    ALERT_EQUIPMENT: 4,
    ALARM_EQUIPMENT: 5,
    IRRIGATING: 6,
    MOVING_EQUIPMENT: 7,
    WAITING_POSITION: 8
  },
  EXTERNAL_ROUTES: {
    WEBHOOK: {
      BASE: process.env.VUE_APP_WEBHOOK_BASE_URL,
      CONFIRM_SESSION: '/confirmSession',
      GET_CONFIGURATION: '/getConfig'
    }
  },
  CODE_EXPIRATION_MINUTES: 5,
  HTTP_STATUS_CODES: {
    OK: 200,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
  },
  EVENTS: {
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    TO_NOT_FOUND: 'To Not Found',
    GET_CONFIG: 'Get Config',
    OPEN_APP: 'Open App',
    CLOSE_APP: 'Close App',
  },
  MAPS_SETTINGS: {
    Z_INDEX: {
      CROP: 1,
      DEVICE: 2
    }
  },
  NAME_TIMERS: {
    NEVER: {
      VALUE: 1,
    },
    ALWAYS: {
      VALUE: 2
    },
    AFTER_10_SECONDS: {
      VALUE: 3,
      TIME: 10
    },
    AFTER_30_SECONDS: {
      VALUE: 4,
      TIME: 30
    }
  }
}