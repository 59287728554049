import _includes from 'lodash/includes'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _zip from 'lodash/zip'
import _some from 'lodash/some'
import constants from '@/constants'
const geolib = require('geolib')

const { LANGUAGES, EQUIPMENT_SUBTYPE } = constants

/**
 * Get the browser language.
 * @returns {string} Language of the user's browser.
 */
export function getBrowserLanguage () {
  const browserLanguage = navigator.language || navigator.userLanguage || LANGUAGES.DEFAULT_LANGUAGE
  const language = browserLanguage.split('-')[0]

  if (_includes(LANGUAGES.ACCEPTED_LANGUAGES, language)) return language

  return LANGUAGES.DEFAULT_LANGUAGE
}

/**
 * Calculates the position of the linear device carried to the lot road.
 * @param {Object} currentLot Object of the device current lot.
 * @param {Object} currentLot.startPoint Coordinates of the current lot start point.
 * @param {number} currentLot.startPoint.lat Latitude of the lot start point.
 * @param {number} currentLot.startPoint.lng Longitude of the lot start point.
 * @param {Object} currentLot.endPoint Coordinates of the current lot end point.
 * @param {number} currentLot.endPoint.lat Latitude of the lot end point.
 * @param {number} currentLot.endPoint.lng Longitude of the lot end point.
 * @param {Object} position Current coordinates of the device.
 * @param {number} position.lat Latitude of the device current position.
 * @param {number} position.lng Longitude of the device current position.
 * @returns {Object} Coordinates of the linear device position carried to the lot road.
 */
function getLinearDeviceCenteredPosition (currentLot, position) {
  const posA = {latitude: currentLot.startPoint.lat, longitude: currentLot.startPoint.lng}
  const posB = {latitude: currentLot.endPoint.lat, longitude: currentLot.endPoint.lng}
  const posP = {latitude: position.lat, longitude: position.lng}

  const angle = geolib.getGreatCircleBearing(posA, posB)
  const dist = geolib.getDistanceFromLine(posP, posA, posB)
  const distToP = geolib.getDistance(posA, posP)
  const distToNewP = Math.sqrt(Math.abs(distToP * distToP - dist * dist))

  const newPosition = geolib.computeDestinationPoint(posA, distToNewP, angle)
  return {
    lat: newPosition.latitude,
    lng: newPosition.longitude
  }
}

/**
 * Calculates the perpendicular coordinate of the current position taken to the line at the other end of the lot.
 * @param {Object} currentLot Object of the device current lot.
 * @param {Object} currentLot.startPoint Coordinates of the current lot start point.
 * @param {number} currentLot.startPoint.lat Latitude of the lot start point.
 * @param {number} currentLot.startPoint.lng Longitude of the lot start point.
 * @param {Object} currentLot.endPoint Coordinates of the current lot end point.
 * @param {number} currentLot.endPoint.lat Latitude of the lot end point.
 * @param {number} currentLot.endPoint.lng Longitude of the lot end point.
 * @param {Object} position Current coordinates of the device.
 * @param {number} position.lat Latitude of the device current position.
 * @param {number} position.lng Longitude of the device current position.
 * @param {number} deviceLength Length of the linear device arm in meters.
 * @returns {Object} Coordinates of the linear device current position taken to the line at the other end of the lot.
 */
function getLinearTwoWingsDeviceArmLine (currentLot, position, deviceLength) {
  let centeredPosition = getLinearDeviceCenteredPosition(currentLot, position)
  centeredPosition = {
    latitude: centeredPosition.lat,
    longitude: centeredPosition.lng
  }
  const currentPositionCoordinates = {
    latitude: position.lat,
    longitude: position.lng
  }


  // Calculates the perpendicular coordinate of the current position taken to the line at the other end of the lot
  const armAngle = geolib.getGreatCircleBearing(currentPositionCoordinates, centeredPosition)
  const newPosition1 = geolib.computeDestinationPoint(centeredPosition, deviceLength / 2, armAngle + 180)
  const newPosition2 = geolib.computeDestinationPoint(centeredPosition, deviceLength / 2, armAngle)

  return [
    {
      lat: newPosition1.latitude,
      lng: newPosition1.longitude
    }, {
      lat: newPosition2.latitude,
      lng: newPosition2.longitude
    }
  ]
}

/**
 * Calculates the perpendicular coordinate of the current position carried to the lot road.
 * @param {Object} currentLot Object of the device current lot.
 * @param {Object} currentLot.startPoint Coordinates of the current lot start point.
 * @param {number} currentLot.startPoint.lat Latitude of the lot start point.
 * @param {number} currentLot.startPoint.lng Longitude of the lot start point.
 * @param {Object} currentLot.endPoint Coordinates of the current lot end point.
 * @param {number} currentLot.endPoint.lat Latitude of the lot end point.
 * @param {number} currentLot.endPoint.lng Longitude of the lot end point.
 * @param {Object} position Current coordinates of the device.
 * @param {number} position.lat Latitude of the device current position.
 * @param {number} position.lng Longitude of the device current position.
 * @param {number} deviceLength Length of the linear device arm in meters.
 * @returns {Object} Coordinates of the linear device current position carried to the lot road.
 */
function getLinearOneWingDeviceArmLine (currentLot, position, deviceLength) {
  let centeredPosition = getLinearDeviceCenteredPosition(currentLot, position)
  centeredPosition = {
    latitude: centeredPosition.lat,
    longitude: centeredPosition.lng
  }
  const currentPositionCoordinates = {
    latitude: position.lat,
    longitude: position.lng
  }


  // Calculates the perpendicular coordinate of the current position taken to the line at the other end of the lot
  const armAngle = geolib.getGreatCircleBearing(currentPositionCoordinates, centeredPosition)
  const newPosition = geolib.computeDestinationPoint(centeredPosition, deviceLength, armAngle + 180)

  return [
    {
      lat: newPosition.latitude,
      lng: newPosition.longitude
    }, {
      lat: centeredPosition.latitude,
      lng: centeredPosition.longitude
    }
  ]
}

/**
 * Calculates the coordinates of the device arm line.
 * @param {number} linearSubtype Value of linear device subtype.
 * @param {Object} currentLot Object of the device current lot.
 * @param {Object} currentLot.startPoint Coordinates of the current lot start point.
 * @param {number} currentLot.startPoint.lat Latitude of the lot start point.
 * @param {number} currentLot.startPoint.lng Longitude of the lot start point.
 * @param {Object} currentLot.endPoint Coordinates of the current lot end point.
 * @param {number} currentLot.endPoint.lat Latitude of the lot end point.
 * @param {number} currentLot.endPoint.lng Longitude of the lot end point.
 * @param {Object} position Current coordinates of the device.
 * @param {number} position.lat Latitude of the device current position.
 * @param {number} position.lng Longitude of the device current position.
 * @param {number} deviceLength Length of the linear device arm in meters.
 * @returns {Array} Path of the device arm line.
 */
export function getLinearDeviceArmLinePath (linearSubtype, currentLot, position, deviceLength) {
  if (linearSubtype == EQUIPMENT_SUBTYPE.LINEAR.TWO_WINGS) return getLinearTwoWingsDeviceArmLine(currentLot, position, deviceLength)

  return getLinearOneWingDeviceArmLine(currentLot, position, deviceLength)
}

/**
 * Calculates the corner coordinates of the current lot.
 * @param {number} linearSubtype Value of linear device subtype.
 * @param {Object} currentLot Object of the device current lot.
 * @param {Object} currentLot.startPoint Coordinates of the current lot start point.
 * @param {number} currentLot.startPoint.lat Latitude of the lot start point.
 * @param {number} currentLot.startPoint.lng Longitude of the lot start point.
 * @param {Object} currentLot.endPoint Coordinates of the current lot end point.
 * @param {number} currentLot.endPoint.lat Latitude of the lot end point.
 * @param {number} currentLot.endPoint.lng Longitude of the lot end point.
 * @param {Object} position Current coordinates of the device.
 * @param {number} position.lat Latitude of the device current position.
 * @param {number} position.lng Longitude of the device current position.
 * @param {number} deviceLength Length of the linear device arm in meters.
 * @returns {Array} Corner coordinates of the current lot.
 */
export function getCropPoints (linearSubtype, currentLot, position, deviceLength) {
  const currentLotCoordinates = {
    startPoint: {
      latitude: currentLot.startPoint.lat,
      longitude: currentLot.startPoint.lng
    },
    endPoint: {
      latitude: currentLot.endPoint.lat,
      longitude: currentLot.endPoint.lng
    }
  }

  // Calculate the corner coordinates of the lot
  let coord1, coord2, coord3, coord4
  if (linearSubtype == EQUIPMENT_SUBTYPE.LINEAR.TWO_WINGS) {
    const perpendicularAngle = geolib.getGreatCircleBearing(currentLotCoordinates.startPoint, currentLotCoordinates.endPoint) - 90
    coord1 = geolib.computeDestinationPoint(currentLotCoordinates.startPoint, deviceLength / 2, perpendicularAngle)
    coord2 = geolib.computeDestinationPoint(currentLotCoordinates.startPoint, deviceLength / 2, perpendicularAngle + 180)
    coord3 = geolib.computeDestinationPoint(currentLotCoordinates.endPoint, deviceLength / 2, perpendicularAngle + 180)
    coord4 = geolib.computeDestinationPoint(currentLotCoordinates.endPoint, deviceLength / 2, perpendicularAngle)
  } else {
    // If device does not have position, return crop path line coordinates
    if (position.lat === 0 && position.lng === 0) return [currentLot.startPoint, currentLot.endPoint]

    // Get the position of the linear device carried to the lot road
    let centeredPosition = getLinearDeviceCenteredPosition(currentLot, position)

    // Parse coordinates to the right format.
    centeredPosition = {
      latitude: centeredPosition.lat,
      longitude: centeredPosition.lng
    }
    const currentPositionCoordinates = {
      latitude: position.lat,
      longitude: position.lng
    }
    const angleFromCenterToCurrentPosition = geolib.getGreatCircleBearing(centeredPosition, currentPositionCoordinates)
    coord1 = geolib.computeDestinationPoint(currentLotCoordinates.startPoint, deviceLength, angleFromCenterToCurrentPosition)
    coord2 = currentLotCoordinates.startPoint
    coord3 = currentLotCoordinates.endPoint
    coord4 = geolib.computeDestinationPoint(currentLotCoordinates.endPoint, deviceLength, angleFromCenterToCurrentPosition)
  }

  return [
    {lat: coord1.latitude, lng: coord1.longitude},
    {lat: coord2.latitude, lng: coord2.longitude},
    {lat: coord3.latitude, lng: coord3.longitude},
    {lat: coord4.latitude, lng: coord4.longitude}
  ]
}

/**
 * Compare the IDs of two devices arrays without taking into account their order
 * @param {Array<Array<Object>>} oldGroups Group of devices.
 * @param {Array<Array<Object>>} newGroups Group of devices to compare.
 * @returns {Boolean} If the devices of the compared groups are different return true.
 */
function compareDeviceArrays(oldDevices, newDevices) {
  const oldIds = _map(oldDevices, 'id').sort()
  const newIds = _map(newDevices, 'id').sort()

  return !_isEqual(oldIds, newIds)
}

/**
 * Compare devices groups
 * @param {Array<Array<Object>>} oldGroups Array of devices groups.
 * @param {Array<Array<Object>>} newGroups Array of devices groups to compare.
 * @returns {Boolean} If the devices in the groups have changed return true.
 */
export function hasChangesInDeviceGroups(oldGroups, newGroups) {
  if (oldGroups.length != newGroups.length) return true

  return _some(_zip(oldGroups, newGroups), ([oldGroup, newGroup]) =>
    compareDeviceArrays(oldGroup, newGroup)
  )
}

/**
 * Takes an array of coordinates and calculates the center of it.
 * @param {Object} crop Current lot object.
 * @returns {Object} Center coordinates object.
 */
export function getLinearCropCenter (crop) {
  const cropCoordinates = {
    startPoint: {
      latitude: crop.startPoint.lat,
      longitude: crop.startPoint.lng
    },
    endPoint: {
      latitude: crop.endPoint.lat,
      longitude: crop.endPoint.lng
    }
  }
  const centerCoordinates = geolib.getCenter([cropCoordinates.startPoint, cropCoordinates.endPoint])
  return {
    lat: centerCoordinates.latitude,
    lng: centerCoordinates.longitude
  }
}